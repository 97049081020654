import Xray from "./xray.component";
import RedoScansPhotos from "./redo-scans-photos.component";
import Checkup from "./checkup.component";
import PostIprPhotos from "./post-ipr-photos.component";
import ScreeningInformation from "./screening-information.component";
import AppointmentPhotos from "./appointment-photos.component";
import AppointmentScans from "./appointment-scans.component";
import PostTreatmentPhotos from "./post-treatment-photos.component";
import MediaFiles from "./media-files.component";
import AttachmentFiles from "./attachments-files.component";
import ButtonsFiles from "./buttons-files.component";

const AppointmentProcedureFiles = ({
  caseRecord,
  index,
  handleTabsExpand,
  appointmentId,
  procedure,
  openCloseTabs,
  setDisable,
  disable,
  userId,
  scrollToExpandedTab,
}) => {
  let procedureFiles = <></>;
  const { type, data } = caseRecord;

  switch (type) {
    case "user_case_record_screening_photos":
      procedureFiles = (
        <AppointmentPhotos
          index={index}
          data={data}
          handleTabsExpand={handleTabsExpand}
          appointmentId={appointmentId}
          appointmentType={procedure}
          openCloseTabs={openCloseTabs}
          disable={disable}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "user_case_record_information":
      procedureFiles = (
        <ScreeningInformation
          index={index}
          data={data}
          userId={userId}
          setDisable={setDisable}
          handleTabsExpand={handleTabsExpand}
          appointmentId={appointmentId}
          appointmentType={procedure}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "user_case_record_scans":
      procedureFiles = (
        <AppointmentScans
          index={index}
          disable={disable}
          data={data}
          handleTabsExpand={handleTabsExpand}
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "user_case_record_xrays":
    case "user_case_record_xrays_mandatory":
      procedureFiles = (
        <Xray
          data={data}
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "user_case_record_post_redo_photos":
      procedureFiles = (
        <RedoScansPhotos
          data={data}
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "checkup_information":
    case "checkup_information_optional":
      procedureFiles = (
        <Checkup
          data={data}
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
    case "user_case_record_post_ipr_photos":
      procedureFiles = (
        <PostIprPhotos
          data={data}
          userId={userId}
          appointmentId={appointmentId}
          appointmentType={procedure}
          index={index}
          handleTabsExpand={handleTabsExpand}
          openCloseTabs={openCloseTabs}
          scrollToExpandedTab={scrollToExpandedTab}
        />
      );
      break;
      case "user_case_record_post_treatment_photos":
        procedureFiles = (
          <PostTreatmentPhotos
            data={data}
            userId={userId}
            appointmentId={appointmentId}
            appointmentType={procedure}
            index={index}
            handleTabsExpand={handleTabsExpand}
            openCloseTabs={openCloseTabs}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        );
        break;
      case "user_case_record_media":
      case "user_case_record_media_optional":
        procedureFiles = (
          <MediaFiles
            data={data}
            userId={userId}
            appointmentId={appointmentId}
            appointmentType={procedure}
            index={index}
            handleTabsExpand={handleTabsExpand}
            openCloseTabs={openCloseTabs}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        );
        break;
      case "user_case_record_attachments":
        procedureFiles = (
          <AttachmentFiles
            index={index}
            data={data}
            handleTabsExpand={handleTabsExpand}
            appointmentId={appointmentId}
            appointmentType={procedure}
            openCloseTabs={openCloseTabs}
            disable={disable}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        );
        break;
      case "user_case_record_buttons":
        procedureFiles = (
          <ButtonsFiles
            index={index}
            data={data}
            handleTabsExpand={handleTabsExpand}
            appointmentId={appointmentId}
            appointmentType={procedure}
            openCloseTabs={openCloseTabs}
            disable={disable}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        );
        break;
      default:
        procedureFiles = <></>;
        break;
  }

  return procedureFiles;
};

export default AppointmentProcedureFiles;
