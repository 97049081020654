import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function FaqLearnMore({ align, unorderedListStyle, isArabic }) {
  const { t } = useTranslation();
  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontWeight: "bold", fontSize: "16px", mb: 2 };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "center" }}
        component="h1"
      >
        {t("faq-header")}
      </Typography>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-1")}
        </Typography>
        <Typography variant="div">{t("faq-info-1-1")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-1-2")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-1-3")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-1-4")}</Typography>
        <br />
        <ol style={unorderedListStyle}>
          <li>{t("faq-info-1-5")}</li>
          <li>{t("faq-info-1-6")}</li>
          <li>{t("faq-info-1-7")}</li>
        </ol>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-2")}
        </Typography>
        <Typography variant="div">{t("faq-info-2-1")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-2-2")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-2-3")}</Typography>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-3")}
        </Typography>
        <Typography variant="div">{t("faq-info-3-1")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-3-2")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-3-3")}</Typography>
        <br />
        <br />
        <Typography variant="div">{t("faq-info-3-4")}</Typography>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-4")}
        </Typography>
        <Typography variant="div">{t("faq-info-4-1")}</Typography>
        <br />
        <br />
        <ul style={unorderedListStyle}>
          <li>{t("faq-info-4-2")}</li>
          <li>{t("faq-info-4-3")}</li>
          <li>{t("faq-info-4-4")}</li>
          <li>{t("faq-info-4-5")}</li>
        </ul>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-5")}
        </Typography>
        <Typography variant="div">{t("faq-info-5-1")}</Typography>
        <br />
        <br />
        <ul style={unorderedListStyle}>
          <li>{t("faq-info-5-2")}</li>
          <li>{t("faq-info-5-3")}</li>
          <li>{t("faq-info-5-4")}</li>
        </ul>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-6")}
        </Typography>
        <Typography variant="div">{t("faq-info-6-1")}</Typography>
        <br />
        <br />
        <a
          href={`https://basma.com/${
            isArabic ? "ar" : "en"
          }/ae/how-much-are-invisible-braces`}
          target="_blank"
          rel="noreferrer"
        >
          {`https://basma.com/${
            isArabic ? "ar" : "en"
          }/ae/how-much-are-invisible-braces`}
        </a>
      </div>
      <div style={divStyles}>
        <Typography variant="h6" sx={title}>
          {t("faq-header-7")}
        </Typography>
        <ol style={unorderedListStyle}>
          <li>{t("faq-info-7-1")}</li>
          <li>{t("faq-info-7-2")}</li>
        </ol>
      </div>
    </>
  );
}

export default FaqLearnMore;
