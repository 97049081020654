import { Box, makeStyles, Typography } from '@material-ui/core'
import { DropZone } from '@shopify/polaris';
import React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(()=>{return{
    relative:{
        position:'relative',
    },
    imgMargBorder:{
        marginTop: '1rem', 
        borderRadius: '2.8px',
        height: '69.7px',
        width: '70.19px',
        cursor:'pointer'
    },
    backgroundColorImg:{ 
        backgroundColor: '#DFE3E8'
    },
    imagePlaceholder:{
        position:'absolute',
        width: '62px',
        height: '15px',
        left:'50%',
        transform: 'translateX(-50%)',
        color: '#FFFFFF',
        backgroundColor:'rgba(0,0,0,0.6)',
        fontSize: '9px !important',
        letterSpacing: 0,
        lineHeight: '13px',
        textAlign: 'center',
        bottom: 10,
        ['@media only screen and (max-width: 500px) '] : {
            transform: 'translateX(7%)',
            left:0
        }
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center",
        padding : 30
   },
   dropZoneDesign:{
        width:'100%',
        height:'100%',
        position:'absolute',
        top:0,
        right:0,
        border: '0.1rem solid #000',
        opacity: 0,
        '& div':{
            height: '100%',
            '& div':{
                height: '100%',
            }
        }
   }
}})
export default function SinglePhoto({handleImages, id, value, disable, fileName = null, fileType = null}) {
    const classes = useStyles();
    const { t } = useTranslation();
  return (
    <Box className={classes.relative}>
        {value ?
            <label htmlFor={id}> 
                {(fileType && fileType.includes('video')) ?
                    <video className={classes.imgMargBorder} src={value} controls /> :
                    <Box component="img" className={classes.imgMargBorder} src={value} />
                }
            </label>
        :
            <label htmlFor={id}> 
                <Box className={`${classes.imgMargBorder} ${classes.backgroundColorImg}`} />
            </label>
        }
        <Typography className={classes.imagePlaceholder}>{value ? t('Replace') : t('Upload-image')}</Typography>
        <div
            className={classes.dropZoneDesign}
        >
            <DropZone
                disabled={disable}
                onDropAccepted={(file) => fileName ? handleImages(file, id, fileName) : handleImages(file, id)}
                id={id}
            >
            </DropZone>
        </div>
    </Box>
  )
}
