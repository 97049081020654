import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect } from 'react'
import ActiveContext from '../../Context/Context';
import Layout from '../../Components/TopNav/Topnav'
import Booking_Calendar from './Booking_Calendar';
import Cookies from 'js-cookie';

const useStyles = makeStyles(()=>{ return {

    loadingBox:{
        margin: "12% auto",
        textAlign: "center"
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
 } });

export default function BookingLanding() {
    const classes = useStyles()
    const { loading } = useContext(ActiveContext);  
    useEffect(() => {
        Cookies.set('rescheduling', true);
    }, [])  
    return (
        <Layout>
            {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>  : <Booking_Calendar />}
        </Layout>
    )
}