import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ProcedureCheckboxList from "./procedures-checkbox-list.component";
import instance from "../../util/axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const SplitProceduresDialog = ({
  completedProceduresDialog,
  setCompletedProceduresDialog,
  updatePatientActions,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const checkboxRefs = useRef([]);

  const {
    openCompletedProceduresDialog,
    appointmentProcedures,
    userId,
    scanId,
  } = completedProceduresDialog;
  const handleDialogClose = () => {
    setCompletedProceduresDialog((prev) => ({
      ...prev,
      openCompletedProceduresDialog: false,
    }));
  };

  const completeAppointmentProcedure = async (procedures) => {
    let doctor = Cookies.get('clinic_name');
    try {
      setLoading(true);
      const response = await instance.put(
        `/admin/v1/clinic/user/appointments/${scanId}/procedures`,
        { procedures, doctor }
      );
      if (response.status === 200) {
        setLoading(false);
        handleDialogClose();
        await updatePatientActions("Completed", userId, scanId, true);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleConfirm = async () => {
    const checkedProcedures = checkboxRefs.current.map((ref, index) => ({
      label: appointmentProcedures[index].label,
      value: ref.checked,
    }));
    const notAllCompleted = checkedProcedures.some(
      (procedure) => procedure.value === false
    );
    if (notAllCompleted) {
      await completeAppointmentProcedure(checkedProcedures);
    }
  };

  return (
    <ConfirmationDialog
      open={openCompletedProceduresDialog}
      handleClose={handleDialogClose}
      handleConfirm={handleConfirm}
      confirmationButtonText={t("Confirm")}
      dialogTitle={t("split-procedure-dialog-title")}
      dialogContentText={t("split-procedure-dialog-context")}
      loading={loading}
      enableDialog={true}
    >
      <ProcedureCheckboxList
        checkboxRefs={checkboxRefs}
        procedures={appointmentProcedures}
        setCompletedProceduresDialog={setCompletedProceduresDialog}
      />
    </ConfirmationDialog>
  );
};

export default SplitProceduresDialog;
