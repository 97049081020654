import { Box, IconButton, makeStyles, Typography,Checkbox ,FormControlLabel, Button, Menu, Grid, MenuItem, Radio, RadioGroup, TextField, Snackbar, InputLabel  } from '@material-ui/core'
import React, {useEffect, useState, useContext } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EnhancedTable from '../DataTable/EnhancedTable';
import axios from '../../util/axios'
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import Note from '../Note/Note';
import Edit  from '../Note/EditNote' 
import { useNavigate } from 'react-router-dom';
import instance from '../../util/axios';
import { Alert } from '@mui/material';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import { useTranslation } from 'react-i18next';
import { clinicImpersonated, convertNumberToArabic, handleReadNotes } from '../../util/functions';
import Cookies from 'js-cookie';
import ActiveContext from '../../Context/Context';
import SplitProceduresDialog from "../Procedures/split-procedures-dialog.component";
import CalendarFilter from "../Filter/calendar-filter.component";
const useStyles=makeStyles((theme)=>{ return {
    fontFamilyAlmarai:{
        fontFamily:'Almarai !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    today:{
        flex:"1 1 auto",
        display:"inline-block",
        cursor:"pointer",
    },
    boxWrapper: {
    },
    displayedDate: {
        fontSize: '27px!important',
        fontFamily: 'Eina-semi!important',
        fontWeight: 500,
        width: '8em'
    },
    displayedDateComplex: {
        fontSize: '27px!important',
        fontFamily: 'Eina-semi!important',
        fontWeight: 500,
    },
    box:{
        display:'flex',
        flexWrap: 'wrap',
        alignItems:"center",
        justifyContent:"space-around",
        '@media only screen and (max-width: 700px) ' : {
            justifyContent:"unset",
            gap: '5%',
            rowGap: "10px !important",
           },
    },
    calendarLayout: {
        backgroundColor:"#ff2c46",
        padding:15,
        color:'white',
        fontSize:20,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    checkContainer:{
     flex:"1 1 auto",
    margin:0,
    minWidth:'fit-content'
    },
    appointment:{
        flex:"1 1 auto",
        display:"inline-block",
        minWidth:'fit-content',
        color: '#9B9BA1',
        fontWeight: "Eina-semi!important",
        fontSize: '14px!important'
    },
    appointmentHide:{
        color: '#212B36',
        fontWeight:'400',
        fontSize: '12px!important'
    },
    datePicker:{
        flex:"1 1 auto",
        //remove to keep same font
        "& input": {
            fontSize: "2em",
            fontFamily:"Eina-semi!important",
            [theme.breakpoints.between('sm','md')]: {
            fontSize:'1.4em',
         },
         [theme.breakpoints.down('sm')]: {
            fontSize:'1em',
         },
          },
        maxWidth:'20%',
        '@media only screen and (max-width: 800px) ' : {
            maxWidth:'25%',
            minWidth:'fit-content'
        },
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center",
        padding : 30
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    icon:{
         ['@media only screen and (max-width: 800px) '] : {
           padding:0
          },
    },
    calendarButton: {
        borderRadius:50,
        width:'22px',
        height:44
    },
    iconBox:{
        flex:"1 1 auto",
       minWidth:'fit-content'
   },
   alertMargin:{
    marginBottom:"1.3rem"
   },
   menuBox: {
    display: "flex"
    },
    filterButtonWithArrow: {
        border: '1px lightgray solid',
        borderRadius: 0,
        // borderRight: 'none'
    },
    RadioGroup:{
        "& li":{
            paddingLeft:0
        }
    },
    filterWrapper: {
        flex:"1 1 auto",
        display: "flex",
        padding: '20px 0',
        margin: '0.5rem 0'
    },
    alertBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alert: {
        alignItems: 'center',
    },
    searchBar: {
        "&:focus":{
            outlineColor: "#ff2c46",
            outlineStyle: "solid",
        },
        '&:focus::WebkitSearchCancelButton':{
            color:"red",
            backgroundColor:"black"
        },
        '&:focus::-ms-clear':{
            color:"red",
            backgroundColor:"black"
        },
        // end
        // width: '60%',
        zIndex:10,
        minWidth: '250px',
        width: '25%',
        paddingLeft: 10,
        height:41,
        border: '1px lightgray solid',
        '&::placeholder': {
            fontWeight: 400,
          },
          "& label.Mui-focused": {
            color: "#ff2c46"
          },
    },
   addNote: {
    width:"25%",
    textAlign: 'center',
    marginTop: 10,
    backgroundColor:"#FF2C46",
    fontWeight:"700",
    float:"right",
    boxSizing:" border-box",
    border: "1px solid #C4CDD5",
    borderRadius: "3px",
    boxShadow:" 0 1px 0 0 rgba(22,29,37,0.05)",

 } }});

export default function Calendar(){
    const { t, i18n } = useTranslation()
    const moment = extendMoment(originalMoment);
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const[checkHide,setCheckHide]=useState(false)
    const [error, setError] = useState(false);
    const[isScanAtHome, setIsScanAtHome] = useState(false);
    const[products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [field, setField] = useState('booking_time');
    const [sort, setSort] = useState('asc')
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [notificationLoading, setnotificationLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    const [openActionConfirm, setOpenActionConfirm] = useState({open : false, data: null});
    const [selectedDoctor, setSelectedDoctor] = useState("");
    const [otherDoctor, setOtherDoctor] = useState("");
    const [enableDialog, setEnableDialog] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [noShowConfirmDialog, setNoShowConfirmDialog] = useState({value: '', open: false, userId: '', scanId: ''});
    const [completedProceduresDialog, setCompletedProceduresDialog] = useState(
      {
          openCompletedProceduresDialog: false, 
          appointmentProcedures: [],
          userId: null,
          scanId: null
      });
    const openCalendar = Boolean(anchorEl);
    const open = Boolean(anchorElFilter);
    const {
        filesImage,setFilesImage,imagesKey,setImageKey,images,setImages
      } = useContext(ActiveContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };
    const handleCloseCalendar = () => {
    setAnchorEl(null);
    };
    const handleTableLoading = (value) => {
        setTableLoading(value);
    }
    //notes
    const[openEditNote,setOpenEditNote]=useState(0)
    const[openAddNote,setOpenAddNote]=useState(0)
    const[notes,setNotes]=useState([])
    const[editNote,setEditNote]=useState({})
    const[newNote,setNewNote]=useState("")
    // end of notes
    const [message, setMessage] = useState('');
    const [singleRow, setSingleRow] = useState({})
    const [openAlert, setOpenAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();

    const {
      searchCalendar,
      calendarFilter,
      state,
      openNote,
      appointmentTypeFilter,
      actions: {
        setSearch,
        setOpenNote,
        handleCalendarSearch,
        setSearchCancelled,
        setSearchPatients,
        handleKeyPressCalendar,
        setCalendarfilter,
        setAppointmentTypeFilter,
        setState,
        setPendingBooking,
      },
    } = useContext(TabsControllerContext);

    const SelectToday=()=>{
        setState({...state, value: moment.range(moment(), moment())})
    }
    const SelectPrevToday=()=>{
        setState({...state, value: moment.range(state.value.start, state.value.start.subtract("1", "days"))})
    }
    const SelectNextToday=()=>{
        setState({...state, value: moment.range(state.value.start, state.value.start.add("1", "days"))})
    }

    //Notes
    useEffect(async()=>{
        if(!openNote){
            setNotes([])
            setOpenEditNote(0)
            setEditNote({})
            return;//not chosen a note
        }
        if(!openEditNote){
            //not chosen a comment to edit but chosen a user to view the note
            try{
            setnotificationLoading(true)
            const res= await axios.get(`/admin/v1/users/${openNote}/comments`)
            if(res.data.success){
                setNotes(res.data.data.data)
                const newData = handleReadNotes(data, openNote, 'unread_comments', false)
                setData(newData)
            }
            }catch(err){
                console.log(err)
            }finally{
            setnotificationLoading(false)
            }
            
        }
        else{
            //chosen a comment to edit display it
            try{
                setnotificationLoading(true)
                const res= await axios.get(`/admin/v1/users/${openNote}/comments/${openEditNote}`)
                if(res.data.success){
                    setEditNote(res.data.data.comment)
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
        }
    

    },[openNote,openEditNote,openAddNote])
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };
    const handleClose=()=>{
        if(openEditNote){
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            setNotes([])
            setNewNote("")
            setOpenAddNote(0)
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleConfirm=async()=>{
        if(openEditNote){
            //update before closing edit modal
            let body={comment:editNote,images:imagesKey}
            try{
                setnotificationLoading(true)
                const res= await axios.post(`/admin/v1/users/${openNote}/comments/${openEditNote}`,body)
                if(res.data.success){
                console.log('updated')
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            if(newNote){
                let body={comment:newNote,images:imagesKey}
                try{
                    setnotificationLoading(true)
                    const res= await axios.post(`/admin/v1/users/${openNote}/comments`,body)
                    if(res.data.success){
                    console.log('added')
                    }
                    }catch(err){
                        console.log(err)
                    }finally{
                    setOpenAddNote(0)
                    setNewNote("")
                    }
                }
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleOutsideClick=()=>{
        setOpenNote(0)
        setOpenAddNote(0)
        setOpenEditNote(0)
        setNewNote("")
        setEditNote({})
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    useEffect(()=>{
        setSearch("");
        setSearchPatients("");
        setSearchCancelled("");
        if(!state.value){
            return 
        }
        setTableLoading(true)
        axios.get(`/admin/v1/clinic/appointment/calendar?clinic_id=${Cookies.get('clinic_id')}&is_scan_at_home=${Cookies.get('is_scan_at_home')}&search=${searchCalendar}&page=${page}&type=${sort}&per_page=${perPage}&start_date=${moment(state.value.start).format('DD/M/Y')}&end_date=${moment(state.value.end).format('DD/M/Y')}&hide_completed=${checkHide ? 1 : 0}&field=${field}&statusFilter=${calendarFilter}&appointmentTypeFilter=${appointmentTypeFilter}`)
        .then((response) => {
            const dat = response.data.data
            setData(dat)
            setTotal(response.data.total)
            let scanAtHome = response.data.is_scan_at_home;
            setIsScanAtHome(scanAtHome);
            if(scanAtHome){
                setProducts(response.data.products.map(prod => ({label: prod.product.alias, value: prod.id})));
            }
        }).catch((err)=> {
            console.log(err)
        }).finally(() => {
            setLoading(false);
            setTableLoading(false)
        })
    }, [
        state.value,
        checkHide,
        page,
        perPage,
        field,
        sort,
        searchCalendar,
        calendarFilter,
        appointmentTypeFilter,
    ]);

    const headCells = [
        {
            id: 'booking_time',
            label: t('Time'),
            type: 'time',
        },
        {
            id: 'booking_date',
            label: t('Booking-Date'),
            type: 'booking_date',
            preferredTime : 'preferred_time',
            preferredDate : 'preferred_date',
        },
        {
            id: 'first_name',
            label: t('Patient-Name'),
            type: 'appointment_name'
        },
        {
            id: 'phone_number',
            label: t('Phone-Number'),
            type:"phone",
            hideSort: true,
        },
        // {
        //     id: 'user_case',
        //     label: t('Case'),
        //     hideSort: true,
        // },
        {
            id: 'user_sub_status',
            label: t('Status'),
            type: 'number',
            hideSort: true,
        },
        {
            id: 'user_step',
            label: t('Step'),
            type: '',
            hideSort: true,
        },
        {
            id: 'user_type',
            label: t('Type'),
            type: 'type',
            hideSort: true,
        },
        {
            id: 'payment_status',
            label: t('payment-status'),
            hideSort: true,
            hideRow: !isScanAtHome,
        },
        {
            id: 'payment_type',
            label: t('payment-type'),
            hideSort: true,
            hideRow: !isScanAtHome,
        },
        {
            id: 'unread_comments',
            label: '',
            hideRow: true
        },
        {
            id: 'user_action',
            label: t('Action'),
            type: 'menu',
            hideSort: true,
        },
        {
            id: 'preferred_date',
            type: 'time',
            hideSort: true,
            hideRow: true,
        },
        {
            id: 'preferred_time',
            type: 'time',
            hideSort: true,
            hideRow: true,
        },
    ]

    const refreshPage = () => {
        window.location.reload();
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSingleRow(row)
    }
    const handleCloseNoShowDialog = () => {
        setNoShowConfirmDialog((prev) => ({...prev, open: false}));
    }

    const handleConfirmReschedule = async(row) => {
        setLoading(true);
        const name = row.first_name.split(' ');
        const lastName = name.pop();
        const firstName = name.shift();
        const email = row.user_email;
        const phone = row.phone_number;
        let sentId = row.id ?? '';
        let isAppointment = true;
        let scn = row.scn ?? '';
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=true`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`);
        setLoading(false);
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: true });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}${row.id ? '&usiId=' + row.id : ''}`;
            navigate(`/dashboard/booking?${query}`);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
        }
        setOpenDialog(false);
    }

    const menu = [
        { id: 'Confirm', action: () => console.log('edit') },
        { id: 'No Answer', action: () => console.log('delete') },
        { id: 'Reschedule', action: () => console.log('delete') },
        { id: 'Delete', action: () => console.log('delete') },
        { id: 'Cancel', action: () => console.log('delete') },
    ]

    const updatePatientActions = async (value, userId, scanId, dialog)=>{
        if(value === 'Completed' && !dialog){
            let procedures = data.filter(data => data.id === scanId)[0].user_type;
            if (procedures[0] === "First Visit" || procedures[0] === "Walk-in") {
                setOpenActionConfirm({open : true, data : { value, userId, scanId }});
            } else {
                procedures = procedures.map((procedure => {return {label: procedure, value: false}}));
                setCompletedProceduresDialog({openCompletedProceduresDialog: true, appointmentProcedures: procedures, userId, scanId});
            }
        }else if (value === 'No Show Up' && !dialog) {
            setNoShowConfirmDialog({value, open: true, scanId, userId});
        } else{
            handleTableLoading(true);
            setLoadingAction(true);
            try{
                let doctor = Cookies.get('clinic_name');
                const response = await instance.put(`/admin/v1/clinic/user/${userId}/sub-status/update`,{
                    action: value,
                    scan_id: scanId,
                    clinicId: Cookies.get('clinic_id'),
                    doctor
                });
               const dataRes = response.data;
               if(dataRes.success){
                   if(dataRes.action_name == 'Cancel'){
                       const newSortedRow = data.filter((row) => row.id != scanId);
                       setData(newSortedRow);
                       setTotal(newSortedRow.length);
                   } else {
                       const newSortedRow = data.map((row)=>{
                            if(row.id == scanId){
                                return {
                                    ...row,
                                    user_sub_status : dataRes.user_sub_status,
                                    user_action : dataRes.user_action
                                }
                            }
                            return row;
                       });
                       setData(newSortedRow);
                   }
                   if(dataRes?.booking_status_completed){
                       const appointmentTypes = data.filter(
                         (data) => data.id === scanId
                       )[0]?.user_type;
                       let url = `/dashboard/users/${userId}/edit-patient-data`;
                       let newUser = dataRes?.new;
                       if (newUser) {
                           url = `/dashboard/users/${userId}/appointments/${scanId}/edit-patient-data`;
                       }
                       navigate(url, {state: appointmentTypes});
                   }
                }else{
                    setOpenAlert(true)
                    setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
               }
            handleTableLoading(false);
            }catch(err){
                setOpenAlert(true)
                setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
                handleTableLoading(false);
    
            } finally {
                setLoadingAction(false);
                handleCloseNoShowDialog();
            }
        }
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setMessage('')
    }
    const onSelect = (value, states) => {
        const start={_d:value.start._d,
            _isAMomentObject: true,
            _isUTC: false,
            _isValid: true,
            _locale: value.start._locale
        }
        const end={_d:value.end._d,
            _isAMomentObject: true,
            _isUTC: false,
            _isValid: true,
            _locale: value.end._locale
        }
        setState( {value: moment.range(start,end)} );
        setAnchorEl(null);
    };
    if(openAlert){
        window.scroll({
            top:0,
            behavior:"smooth"
        })
    }

    const handleRadioButtonsChange = (e, type) => {
        if (type === 'appointmentType') {
            setAppointmentTypeFilter(e.target.value);
        } else if (type === "status") {
            setCalendarfilter(e.target.value);
        }
    }
    return (
        (loading  )? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> :
        <Box className={classes.boxWrapper}>
            {openAlert && (
                <Alert
                    open={openAlert}
                    className={classes.alert}
                    severity="error"
                    onClose={handleCloseAlert}
                >
                    <Box className={classes.alertBox}>
                        {message}
                        {error && (
                            <Button variant="outlined" onClick={refreshPage} color="inherit" size="small">
                                {t('refresh')}
                            </Button>
                        )}
                    </Box>
                </Alert>
            )}
             <Box className={classes.box}>
            {state.value.start.format('DD/MM/YYYY') !== state.value.end.format('DD/MM/YYYY') ? <Typography className={classes.displayedDateComplex}>{moment(state.value.start).format('ddd, MMM DD')} <span> to </span> <span style={{fontSize:"14px"}}>{moment(state.value.end).format('ddd, MMM DD')}</span></Typography> :
            <Typography className={classes.displayedDate}>{moment(state.value.start).format('ddd, MMM DD')}</Typography>
            }
            <Button   
                id="demo-positioned-button"
                aria-controls={openCalendar ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCalendar ? 'true' : undefined}
                onClick={handleClick}
                className={classes.calendarButton}><ArrowDropDownIcon/></Button>
              <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openCalendar}
              onClose={handleCloseCalendar}
              anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
              }}
              transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
              }}
              >
                  <div className={classes.calendarLayout}><span><CalendarTodayIcon/></span> <span>Calendar</span></div>
            <DateRangePicker
            value={moment.range(state.value.start, state.value.end)}
            onSelect={onSelect}
            singleDateRange={true}
            />
          </Menu>
            <Typography color="primary" className={classes.today} onClick={()=>{SelectToday()}} >
                {t('Today')}
            </Typography>
           <Box className={classes.iconBox}>
            <IconButton className={classes.icon} onClick={()=>{SelectPrevToday()}}>
                {i18n.language == 'ar' ? 
                    <ArrowForwardIosIcon />
                    :
                    <ArrowBackIosIcon/>}
            </IconButton>
            <IconButton className={classes.icon}  onClick={()=>{SelectNextToday()}}>
                {i18n.language == 'ar' ? 
                    <ArrowBackIosIcon/>
                    :
                    <ArrowForwardIosIcon />}
            </IconButton>
            </Box>
            <Typography variant='body1' className={classes.appointment} >
            {convertNumberToArabic( t, total)} {t('Appointment')}{total === 0 || total > 1 ? i18n.language == 'ar' ? '' : 's' : ''}
            </Typography>
            <FormControlLabel className={classes.checkContainer} control={<Checkbox checked={checkHide} style={{paddingLeft:"0px"}} onClick={()=>{
                setCheckHide((prev)=>{
                    return !prev;
                })
            }}/>}  label={<Typography variant='body1' className={`${classes.appointmentHide} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} >
            {t('Hide-completed-appointments')}
            </Typography>} />
            </Box>
            {/* search field */}
            <Grid className={classes.filterWrapper} item lg={12} xs={12}>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickFilter}
                    className={classes.filterButtonWithArrow}
                >
                    {!calendarFilter && !appointmentTypeFilter
                        ? t("Filter")
                        : calendarFilter.includes(" ")
                            ? t(calendarFilter.replace(" ", "-"))
                            : `${t(calendarFilter)} ${calendarFilter && appointmentTypeFilter ? "," : ""} ${t(appointmentTypeFilter)}`}{" "}
                    <ArrowDropDownIcon />                
                </Button>
                <CalendarFilter
                    anchorElFilter={anchorElFilter}
                    calendarFilter={calendarFilter}
                    appointmentTypeFilter={appointmentTypeFilter}
                    handleRadioButtonsChange={handleRadioButtonsChange}
                    handleCloseFilter={handleCloseFilter}
                    open={open}
                    t={t}
                />
                        <input 
                            type='search'
                            id="searchstyle"
                            className={classes.searchBar}
                            placeholder={t('Search')}
                            defaultValue={searchCalendar}
                            onKeyPress={(e) => handleKeyPressCalendar(e)}
                            onChange={(e) => handleCalendarSearch(e)}
                            // children={<h5>X</h5>}
                        />
                        

                </Grid>
            {/* search field */}
           {(!tableLoading) ? <EnhancedTable
                rowsData={data}
                updatePatientActions={updatePatientActions}
                handleTableLoading={handleTableLoading}
                loading={tableLoading}
                handleOpenDialog={handleConfirmReschedule}
                headCells={headCells}
                menu={menu}
                setPage={setPage}
                perPage={perPage}
                page={page}
                field={field}
                setField={setField}
                total={total}
                sort={sort}
                setSort={setSort}
                setPerPage={setPerPage}
                setOpenNote={setOpenNote}
                products={products}
                isScanAtHome={isScanAtHome}
                ></EnhancedTable> : <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>
           }
                <SplitProceduresDialog 
                    completedProceduresDialog={completedProceduresDialog} 
                    setCompletedProceduresDialog={setCompletedProceduresDialog} 
                    updatePatientActions={updatePatientActions}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    setEnableDialog={setEnableDialog}
                    otherDoctor={otherDoctor}
                    setOtherDoctor={setOtherDoctor}
                />
                 <ConfirmationDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={handleConfirmReschedule}
                    confirmationButtonText={t('Confirm')}
                    cancelButtonText={t('Cancel')}
                    dialogTitle={t('Reschedule')}
                    dialogContentText={t('Are-you-sure-you-want-to-reschedule?')}
                />
                <ConfirmationDialog
                    open={noShowConfirmDialog.open}
                    handleClose={handleCloseNoShowDialog}
                    handleConfirm={() => updatePatientActions(noShowConfirmDialog?.value, noShowConfirmDialog?.userId, noShowConfirmDialog?.scanId, true)}
                    confirmationButtonText={t('Confirm')}
                    cancelButtonText={t('Cancel')}
                    loading={tableLoading}
                    dialogTitle={t('no-show-confirm-dialog-title')}
                    dialogContentText={t('no-show-confirm-dialog-content')}
                />
                <ConfirmationDialog 
                    open={openActionConfirm.open}
                    handleConfirm={() => updatePatientActions(openActionConfirm?.data?.value, openActionConfirm?.data?.userId, openActionConfirm?.data?.scanId, true)}
                    dialogTitle={t('Confirm')}
                    confirmationButtonText={t('Yes-mark-appointment-as-completed')}
                    dialogContentText={t('mark-the-appointment-completed-confirmation')}
                    isWarning={true}
                    handleClose={()=>(setOpenActionConfirm(false))}
                    loading={loadingAction}
                    confirmBtnStyle={{width:'fit-content'}}
                    enableDialog={true}
                />
                 <ConfirmationDialog  open={openNote>0}
                 handleOutsideClick={()=>{handleOutsideClick()}}
                 handleClose={()=>{handleClose()}}
                 handleConfirm={()=>{handleConfirm()}}
                 notes={true}
                 confirmationButtonText={openEditNote||openAddNote ? t("Save")  : ""}
                 cancelButtonText=  {openEditNote||openAddNote ? t("Cancel")  : ""}  
                 dialogTitle= {openEditNote ? t("Edit-Note")  :openAddNote? t("Add-Note") : t("Notes")}  
                 dialogContentText=""
                 loading={notificationLoading}
                  > {openEditNote  ? 
                  <Edit data={editNote} setData={setEditNote} />
                  : openAddNote ? <Edit data={newNote} setData={setNewNote} />:<><Note  setOpenEditNote={setOpenEditNote} data={notes} setnotificationLoading={setnotificationLoading}/>
                   <Button disabled={clinicImpersonated()} className={`${classes.addNote} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} color="secondary" variant='contained' onClick={()=>{setOpenAddNote(1)}}>{t('Add-Note')}</Button></>}</ConfirmationDialog>
            
        </Box>
    )
}