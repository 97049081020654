import { styled, tableCellClasses, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

const FirstVisitAppointment = ({ isArabic, align, unorderedListStyle }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
    },
  }));

  const { t } = useTranslation();

  const divStyles = {
    mb: 2,
    mt: 2,
    textAlign: align,
    color: "#ff2c46",
    backgroundColor: "#ffffff",
    padding: 2,
    borderRadius: "5px",
    fontWeight: "bold",
  };

  const listHeaderStyle = {
    fontWeight: "bold",
    color: "#000000",
  };

  const rows = [
    {
      name: `1. ${t("waiting-time")}`,
      duration: t("less-than-ten-minutes"),
      responsible: t("medical-assistant"),
    },
    {
      name: `2. ${t("welcome")}`,
      duration: "5",
      responsible: t("medical-assistant"),
    },
    {
      name: `3. ${t("opg-x-ray")}`,
      duration: "5",
      responsible: t("medical-assistant"),
    },
    {
      name: `4. ${t("dental-checkup")}`,
      duration: "5",
      responsible: t("medical-assistant"),
    },
    { name: `5. ${t("cleaning")}`, duration: "5", responsible: t("doctor") },
    { name: `6. ${t("photos")}`, duration: "5", responsible: t("doctor") },
    {
      name: `7. ${t("intra-oral-scan-explanation")}`,
      duration: "10",
      responsible: t("doctor"),
    },
    {
      name: `8. ${t("show-the-box")}`,
      duration: "5",
      responsible: t("doctor"),
    },
    {
      name: `9. ${t("Book-a-tele-consultation")}`,
      duration: "5",
      responsible: t("doctor"),
    },
    {
      name: t("total-treatment-time"),
      duration: t("sixty-minutes"),
      responsible: "",
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, textAlign: "center" }}
        component="h1"
      >
        {t("basma-first-visit-in-the-clinic")}
      </Typography>
      <Typography
        variant="p"
        sx={{
          mb: 2,
          backgroundColor: "white",
          padding: 2,
          borderRadius: "5px",
        }}
        component="p"
      >
        {t("learn-more-intro-1")}
        <br />
        <br />
        {t("learn-more-intro-2")}
        <br />
        <br />
        {t("learn-more-intro-3")}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <caption>{t("learn-more-table-caption-1")}</caption>
          <TableHead>
            <TableRow className="one-heading-table">
              <StyledTableCell variant="head" align="center" colSpan={3}>
                {t("learn-more-table-header")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <StyledTableCell variant="head" align={align}>
                {t("task")}
              </StyledTableCell>
              <StyledTableCell variant="head" align={align}>
                {t("duration")}
              </StyledTableCell>
              <StyledTableCell variant="head" align={align}>
                {t("responsible")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    backgroundColor: "green",
                    color: "white",
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell align={align} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align={align}>{row.duration}</TableCell>
                <TableCell align={align}>{row.responsible}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={divStyles} variant="div" component="div">
        1.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("waiting-time")}
        </Typography>{" "}
        {`(<10 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-waiting-1")}</li>
          <li>{t("learn-more-waiting-2")}</li>
          <li>{t("learn-more-waiting-3")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        2.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("learn-more-welcome")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-welcome-1")}</li>
          <li>{t("learn-more-welcome-2")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        3.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("opg-x-ray")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-opg-1")}</li>
          <li>{t("learn-more-opg-2")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        4.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("dental-checkup")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-dental-1")}</li>
          <li>{t("learn-more-dental-2")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        5.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("cleaning")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-cleaning-1")}</li>
          <li>{t("learn-more-cleaning-2")}</li>
          <li>{t("learn-more-cleaning-3")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        6.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {isArabic ? t("photos-info") : t("photos")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-photos-1")}</li>
          <li>{t("learn-more-photos-2")}</li>
          <li>{t("learn-more-photos-3")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        7.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {isArabic
            ? t("intra-oral-scan-explanation-info")
            : t("intra-oral-scan-explanation")}
        </Typography>{" "}
        {`(10 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-inter-oral-scan-1")}</li>
          <li>{t("learn-more-inter-oral-scan-2")}</li>
          <li>{t("learn-more-inter-oral-scan-3")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        8.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("show-the-box")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-aligner-kit-1")}</li>
          <li>{t("learn-more-aligner-kit-2")}</li>
          <li>{t("learn-more-aligner-kit-3")}</li>
        </ul>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        9.{" "}
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("Book-a-tele-consultation")}
        </Typography>{" "}
        {`(5 ${t("minutes")})`}
        <ul style={unorderedListStyle}>
          <li>{t("learn-more-information-portal-1")}</li>
        </ul>
      </Typography>
    </>
  );
};

export default FirstVisitAppointment;
